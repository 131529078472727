import React, {useState, useEffect} from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import MailChimpForm from '../components/MailChimpForm'

import useWindowSize from '../hooks/useWindowResize'



export default function Contact() {

  const size = useWindowSize();
  return (
    <div className='flex flex-col justify-center items-start'>
    <Navbar showVideo={false}/>
    <div className='w-full mt-6 flex flex-col justify-center items-center'>
      <MailChimpForm />
      <Footer />
      </div>
    </div>
  )
}

import React from "react";
import { InlineWidget } from "react-calendly";

const CalendlyWidget = () => {
  return (
    <div className="w-full">
      <div className='overflow-clip'>
      <InlineWidget styles={{height: '1350px', overflow:'hidden'}} url="https://calendly.com/launchpointllc/app-consultation-design-planning" />
      </div>
    </div>
  );
};

export default CalendlyWidget;
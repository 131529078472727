import React,{ useRef, useEffect, useState } from 'react'
import QuoteSegment from '../components/QuoteSegment'
import Navbar from '../components/Navbar'
import CompanyPartners from '../components/CompanyPartners'
import Footer from '../components/Footer'
import LandingHero from '../components/LandingHero'
import Testimonials from '../components/Testimonials'

export default function Landing() {

  const [showVideo, setShowVideo] = useState(false)

  useEffect(() => {
    const cutoffTime = localStorage.getItem('movieCutoffTime')
    const currentTime = Math.floor( Date.now() / 1000 )
    if (cutoffTime) {
      if (currentTime<cutoffTime) {
        setShowVideo(false)
      }
    }

    localStorage.setItem('movieCutoffTime',currentTime+3600)
  }, [])
  
  useEffect(() => {
    console.log('showVideo',showVideo)
  }, [showVideo])
  


  return (
    <div className='overflow-hidden'>
      <Navbar showVideo={showVideo}/>
      <LandingHero />
      <QuoteSegment />
      <CompanyPartners />
      <Testimonials />
      <Footer />
      
    </div>
  )
}

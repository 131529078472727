import React, {useState, useEffect} from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe"

const CustomForm = ({ status, message, onValidated }) => {


    const [modalOpen, setModalOpen] = useState(false);

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');


    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
        firstName &&
        lastName &&
        email.indexOf("@") > -1 &&
        onValidated({
            MERGE0: email,
            MERGE1: firstName,
            MERGE2: lastName,
            MERGE4: phoneNumber,
        });

    }

    useEffect(() => {
        if(status === "success") clearFields();
        if(modalOpen && status === "success") clearFields();
    }, [status, modalOpen])

    const clearFields = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
    }

    const inputClassName='rounded-md focus:outline-none border-gray ring-theePrimary focus:ring-0'

    return (
        <form
            className="flex flex-col items-center justify-center m-6 p-6 mt-24 rounded-lg drop-shadow-md border-gray-200 bg-white"
            onSubmit={(e) => handleSubmit(e)}
        >
            <h3 className="text-2xl">
                {status === "success" ? "Success!" :
                    <div className='max-w-md text-center m-6 mb-0'>
                    Join the LaunchPoint email list to stay up to date on our latest projects! 🚀
                    </div>}
            </h3>

            {status === "sending" && (
                <div
                    className="mc__alert mc__alert--sending"
                >sending...</div>
            )}
            {status === "error" && (
                <div
                    className="mc__alert mc__alert--error"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <div
                    className="mc__alert mc__alert--success"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}

            {status !== "success" ? (
                <div className="flex flex-col w-full m-3 p-3">
                    <div className='flex text-xs mt-2 mb-1'>
                    <label className=''>First Name</label><span className='text-red-500 ml-1'>*</span>
                    </div>
                    <input
                        className={inputClassName}
                        label="First Name"
                        onChange={(e)=>setFirstName(e.target.value)}
                        type="text"
                        value={firstName}
                        placeholder="Jane"
                        required
                    />
                    <div className='flex text-xs mt-3 mb-1'>
                    <label className=''>Last Name</label><span className='text-red-500 ml-1'>*</span>
                    </div>
                    <input 
                    className={inputClassName}
                        label="Last Name"
                        onChange={(e)=>setLastName(e.target.value)}
                        type="text"
                        value={lastName}
                        placeholder="Doe"
                        required
                    />
                    <div className='flex text-xs mt-3 mb-1'>
                    <label className=''>Email</label><span className='text-red-500 ml-1'>*</span>
                    </div>
                    <input
                    className={inputClassName}
                        label="Email"
                        onChange={(e)=>setEmail(e.target.value)}
                        type="email"
                        value={email}
                        placeholder="your@email.com"
                        required
                    />
                    <label className='text-xs mt-3 mb-1'>Phone</label>
                    <input
                    className={inputClassName}
                        label="PhoneNumber"
                        onChange={(e)=>setPhoneNumber(e.target.value)}
                        type="text"
                        value={phoneNumber}
                        placeholder="+1 (555) 555-5555"
                    />
                </div>
            ) : null}

            {/*Close button appears if form was successfully sent*/}
            {
                status === 'success' ? <button
                    handleClick={() => setModalOpen(false)}
                    label="close"
                    size="big"
                    customClass="g__justify-self-center"
                /> :
                <button
                onClick={e => handleSubmit(e)}
                type="submit"
                className="
                    mt-6
                    block
                    w-full
                    py-3
                    px-4
                    rounded-md
                    shadow
                    hover:bg-themePrimary/90
                    bg-themePrimary
                    text-white
                    font-medium
                    hover:bg-themePrimary
                    focus:outline-none
                    focus:ring-themePrimary
                    focus:ring-offset-gray-900"
                    >
                    Submit
                </button>

            }
        </form>
    );
};


const MailchimpForm = props => {
    const url = `https://gmail.us10.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

    return (

        <div className="mc__form-container">
            <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        </div>

    )
}

export default MailchimpForm;
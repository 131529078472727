import { ChevronLeftIcon, ChartPieIcon, ChatAlt2Icon, CloudUploadIcon, CubeIcon, CurrencyDollarIcon, DatabaseIcon, DocumentSearchIcon, LightningBoltIcon, LocationMarkerIcon, MapIcon, MicrophoneIcon, MusicNoteIcon, OfficeBuildingIcon, PhoneIcon, PlayIcon, ShieldCheckIcon, ShoppingCartIcon, SparklesIcon, SpeakerphoneIcon, UserGroupIcon, UserIcon } from '@heroicons/react/solid'
import React, { useState, useEffect } from 'react'


export default function LandingWizard() {

    const [wizardPage, setWizardPage] = useState(0)
    const [firstSelectedOption, setFirstSelectedOption] = useState('')
    const [secondSelectedOption, setSecondSelectedOption] = useState('')
    const [thirdSelectedOption, setThirdSelectedOption] = useState('')

    const [keyFeatures, setKeyFeatures] = useState([
        {
            icon: UserIcon,
            name: 'User/Admin Accounts',
            selected: false,
        },
        {
            icon: UserGroupIcon,
            name: 'Social Media',
            selected: false,
        },
        {
            icon: ChatAlt2Icon,
            name: 'Chats/DMs',
            selected: false,
        },
        {
            icon: ShieldCheckIcon,
            name: 'Security',
            selected: false,
        },
        {
            icon: CubeIcon,
            name: 'AR/3D',
            selected: false,
        },
        {
            icon: ChartPieIcon,
            name: 'Data Analytics',
            selected: false,
        },
        {
            icon: CloudUploadIcon,
            name: 'Cloud Storage',
            selected: false,
        },
        {
            icon: CurrencyDollarIcon,
            name: 'Financial Data',
            selected: false,
        },
        {
            icon: DatabaseIcon,
            name: 'Database Compliance',
            selected: false,
        },
        {
            icon: DocumentSearchIcon,
            name: 'Search Capabilities',
            selected: false,
        },
        {
            icon: MapIcon,
            name: 'Map Capabilities',
            selected: false,
        },
        {
            icon: LocationMarkerIcon,
            name: 'Navigation Capabilities',
            selected: false,
        },
        {
            icon: MicrophoneIcon,
            name: 'Audio Recording',
            selected: false,
        },
        {
            icon: MusicNoteIcon,
            name: 'Audio Playback',
            selected: false,
        },
        {
            icon: OfficeBuildingIcon,
            name: 'For Large Business',
            selected: false,
        },
        {
            icon: PlayIcon,
            name: 'Video Integrations',
            selected: false,
        },
        {
            icon: PhoneIcon,
            name: 'Live Calls',
            selected: false,
        },
        {
            icon: ShoppingCartIcon,
            name: 'E-Commerce',
            selected: false,
        },
        {
            icon: SparklesIcon,
            name: 'Animations',
            selected: false,
        },
        {
            icon: SpeakerphoneIcon,
            name: 'Push Notifications',
            selected: false,
        },
        {
            icon: LightningBoltIcon,
            name: 'Automated Processes',
            selected: false,
        },
    ])

    const handleAppTypeSelect = (response) => {
        setFirstSelectedOption(response)
        handleIncrement(true)
    }

    const handleSetSelected = (index) => {
        let tempKeyFeatures = [...keyFeatures]
        tempKeyFeatures[index].selected = !tempKeyFeatures[index].selected
        setKeyFeatures(tempKeyFeatures)
    }

    useEffect(() => {
        console.log('firstSelectedOption', firstSelectedOption)
        console.log('secondSelectedOption', secondSelectedOption)
        console.log('thirdSelectedOption', thirdSelectedOption)
    }, [firstSelectedOption, secondSelectedOption, thirdSelectedOption])

    const [email, setEmail] = useState('')
    const [additionalFeatures, setAdditionalFeatures] = useState(false)

    const handleGetQuote = (e) => {
        e.preventDefault()
        console.log('SUBMIT')
        let features = []
        for (let i = 0; i < keyFeatures.length; i++) {
            if (keyFeatures[i].selected) {
                features.push(keyFeatures[i].name)
            }
        }
        let formObj = {
            appType: firstSelectedOption,
            features: features,
            additionalFeatures: additionalFeatures,
            email: email
        }

        console.log(formObj)
    }

    
    const [visibilityA, setVisibilityA] = useState(true)
    const [visibilityB, setVisibilityB] = useState(false)
    const [visibilityC, setVisibilityC] = useState(false)
    const handleIncrement = (increment) => {
        if (increment) {
            if (visibilityA) {
                setVisibilityA(false)
                setVisibilityB(true)
                setVisibilityC(false)
            }
    
            if (visibilityB) {
                setVisibilityA(false)
                setVisibilityB(false)
                setVisibilityC(true)
            }
    
            if (visibilityC) {
                setVisibilityA(false)
                setVisibilityB(false)
                setVisibilityC(false)
            }
        } else {
            if (visibilityA) {
                setVisibilityA(true)
                setVisibilityB(false)
                setVisibilityC(false)
            }
    
            if (visibilityB) {
                setVisibilityA(true)
                setVisibilityB(false)
                setVisibilityC(false)
            }
    
            if (visibilityC) {
                setVisibilityA(false)
                setVisibilityB(true)
                setVisibilityC(false)
            }
        }
        
    }



    const wizardPages = [
        <div className='flex flex-col justify-center text-white'>
            <div>
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                    <span className="block text-themePrimary">How much money would it take to </span>
                    <span className="block text-white">build your Dream App?</span>
                  </h1>
                  <p className="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
                    Not as much as you think.
                  </p>
            </div>
            <div className='my-6 mt-12'>
            Get a quote today:
            </div>
            <div className='flex w-full justify-center'>
            <div className='grid grid-cols-2 md:grid-cols-4'>
                <div onClick={() => handleAppTypeSelect('Mobile App')}
                    className='m-2 cursor-pointer hover:bg-themePrimaryHighlight flex flex-col bg-themePrimary text-white w-32 h-32 text-sm text-center items-center justify-center rounded-lg'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 m-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
                    </svg>
                    Mobile App
                </div>
                <div onClick={() => handleAppTypeSelect('Web App')}
                    className='m-2 cursor-pointer hover:bg-themePrimaryHighlight flex flex-col bg-themePrimary text-white w-32 h-32 text-sm text-center items-center justify-center rounded-lg'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 m-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                    </svg>
                    Web App
                </div>
                <div onClick={() => handleAppTypeSelect('Office Automation')}
                    className='m-2 cursor-pointer hover:bg-themePrimaryHighlight flex flex-col bg-themePrimary text-white w-32 h-32 text-sm text-center items-center justify-center rounded-lg'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 m-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    Office Automation
                </div>
                <div onClick={() => handleAppTypeSelect('Another Solution')}
                    className='m-2 cursor-pointer hover:bg-themePrimaryHighlight flex flex-col bg-themePrimary text-white w-32 h-32 text-sm text-center items-center justify-center rounded-lg'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 m-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    Another Solution
                </div>
                </div>
            </div>
        </div>,
        <div className='flex flex-col justify-center text-white relative'>


            <div className='mb-2'>Select Key Features:</div>
            <div className='flex flex-wrap items-center justify-center'>
                {keyFeatures.map((item, index) => {
                    return (
                        <div
                            onClick={() => handleSetSelected(index)}
                            className={
                                item.selected ?
                                    'flex p-2 m-1 items-center justify-center bg-themePrimaryHighlight hover:bg-themePrimary cursor-pointer rounded-md' :
                                    'flex p-2 m-1 items-center justify-center bg-themePrimary/50 hover:bg-themePrimary cursor-pointer rounded-md'}>
                            <item.icon className='mr-1 h-4 w-4' />
                            <div>{item.name}</div>
                        </div>
                    )
                })}

            </div>
            <div className='p-4 flex w-full justify-center items-center'>
                <div className='mr-2 '>
                    <input
                        onClick={()=>setAdditionalFeatures(!additionalFeatures)}
                        id="comments"
                        checked={additionalFeatures}
                        aria-describedby="comments-description"
                        name="comments"
                        type="checkbox"
                        className="cursor-pointer focus:ring-0 focus:ring-offset-0 h-4 w-4 text-themePrimary ring-0 border-0 outline-none focus:outline-none border-gray-300 rounded"
                    />
                </div>
                <div>My App Includes something not listed here</div>

            </div>
            <div className=' flex w-full items-center justify-center my-4'>
                
                <div
                    onClick={() => handleIncrement(true)}
                    className='w-full flex items-center justify-center max-w-xl rounded-lg bg-themePrimary hover:bg-themePrimaryHighlight cursor-pointer text-lg h-12'>Continue</div>
            </div>
            <div className=' flex w-full items-center justify-center'>
            <div
                    onClick={() => handleIncrement(false)}
                    className='flex
                    items-center
                    justify-center
                    rounded-lg
                    bg-gray-700/50
                    hover:bg-gray-700
                    cursor-pointer
                    h-12
                    w-24
                    mx-2'> <ChevronLeftIcon className='w-4 h-4' /> Back</div>
                    </div>
        </div>,
        <form action="#" className="sm:max-w-xl sm:mx-auto lg:mx-0">
            <div className='flex flex-col text-white text-xl mb-12'>
                <div className='text-2xl m-2 mt-0'>Along with your FREE Quote, you will receive:</div>
                <li>Database Design Template</li>
                <div className='text-sm max-w-xs mx-auto mb-2'>(Including a filled-in Database Design for you to build your designs from!)</div>
                <li>API Design Template</li>
                <div className='text-sm max-w-xs mx-auto mb-2'>(Including a filled-in API Design for you to build your designs from!)</div>
                <li>Demo UX Flow Diagrams</li>
                <div className='text-sm max-w-xs mx-auto mb-2'>(So you can see how you should draw these out)</div>
                <li>Step-by-step guide from idea to release 🚀</li>
                <div className='text-sm max-w-xs mx-auto mb-2'>Our How-To we've put together as we've helped dozens of companies get from zero to Launch.</div>
            </div>
            <div className="sm:flex">
                <div className="min-w-0 flex-1">

                    <label htmlFor="email" className="sr-only">
                        Email address
                    </label>
                    <input
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        id="email"
                        type="email"
                        placeholder="Enter your email"
                        className="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-themePrimary focus:ring-offset-gray-900"
                    />
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                    <button
                        onClick={e => handleGetQuote(e)}
                        type="submit"
                        className="block w-full py-3 px-4 rounded-md shadow bg-themePrimary text-white font-medium hover:bg-themePrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-themePrimary focus:ring-offset-gray-900"
                    >
                        Get Quote
                    </button>
                </div>
            </div>
            <p className="mt-3 text-sm text-gray-300 sm:mt-4">
                Your information is safe with us. By signing up you agree to our <span className='text-xs font-bold cursor-pointer'>Privacy Policy</span>
            </p>
            <div className='w-full flex justify-center mt-4'>
            <div
                    onClick={() => handleIncrement(false)}
                    className='
                    text-white
                    flex
                    items-center
                    justify-center
                    rounded-lg
                    bg-gray-700/50
                    hover:bg-gray-700
                    cursor-pointer
                    h-12
                    w-24
                    mx-2'> <ChevronLeftIcon className='w-4 h-4' /> Back</div></div>
        </form>
    ]



    return (
        <div className='relative flex items-start justify-center min-h-[800px] w-full'>
            
            
            <div className={visibilityA ? 'pointer-events-auto absolute inset-0 translate-x-0 opacity-1 transition-all duration-300':'pointer-events-none absolute inset-0 opacity-0 translate-x-2 transition-all'}>
                {wizardPages[0]}
            </div>
            
             <div className={visibilityB ? 'pointer-events-auto absolute inset-0 translate-x-0 opacity-1 transition-all duration-300':'pointer-events-none absolute inset-0 opacity-0 translate-x-2 transition-all'}>
                {wizardPages[1]}
            </div>
            
            <div className={visibilityC ? 'flex justify-center pointer-events-auto absolute inset-0 translate-x-0 opacity-1 transition-all duration-300':'flex justify-center pointer-events-none absolute inset-0 opacity-0 translate-x-2 transition-all'}>
                {wizardPages[2]}
            </div>
          
        </div>
    )
}

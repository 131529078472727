import React, {useState, useEffect} from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import CheckboxServices from '../components/CheckboxServices'
import CTA from '../components/CTA'

import useWindowSize from '../hooks/useWindowResize'


export default function Services() {

  const size = useWindowSize();
  return (
    <div className='flex flex-col justify-center items-start'>
    <Navbar showVideo={false}/>
    <div className='w-full mt-20 flex flex-col justify-center items-center'>
      <CheckboxServices />
      <CTA text={"Get Started"} consultation={true}/>
      <Footer />
      </div>
    </div>
  )
}

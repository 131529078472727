import React, {useState, useEffect} from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import useWindowSize from '../hooks/useWindowResize'
import ActiveCampaignForm from '../components/ActiveCampaignForm'

import AboutSection from '../components/AboutSection'


export default function Contact() {

  const size = useWindowSize();
  return (
    <div className='flex flex-col justify-center items-start '>
    <Navbar showVideo={false}/>
      <div className='w-full mt-12 flex flex-col justify-center items-center'>
          <AboutSection />
          <Footer />
      </div>
    </div>
  )
}

import React, {useState, useEffect} from 'react';
import CTA from './CTA';
import LPSmallv4 from '../assets/img/LPSmallv4.png'

export default function AboutSection() {
    return (
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-3xl mx-auto p-4">
            

            <h1 className='flex items-center'>
                <div className='flex-grow-1 m-4 h-2 mt-6 w-full  text-transparent bg-transparent border-2 border-tranparent border-b-gray-200 border-t-0 border-x-0'></div>
                
              <span className="mt-2 w-24 sm:w-72 text-2xl text-center font-bold tracking-tight text-gray-900">
                    About Us
              </span>
              
              <div className='flex-grow-1 m-4 h-2 mt-6 w-full text-transparent bg-transparent border-2 border-tranparent border-b-gray-200 border-t-0 border-x-0'></div>
            </h1>
            <div className='flex w-full justify-center h-36 my-6 mb-12'>
                <img alt="logo" src={LPSmallv4} className='object-contain'/>
              </div>
            <p className="mt-8 text-lg text-gray-500 leading-8">
                Based in Denver, CO and with team members located all over the world, LaunchPoint seeks to
                fill a new type of market need for small business, individuals, and
                large-companies alike: </p>
                <div className='w-full text-center my-6'>
                <span className='font-bold'>Micro-apps.</span>
                </div>
            <p className="mt-8 text-lg text-gray-500 leading-8">
                Using a system of proprietary software and a specially-trained team, the engineers at LaunchPoint
                have designed a system which is capable of producing high-powered, scalable apps at the fraction
                of a cost a typical development team would be able to do so.
            </p>
            <p className="mt-8 text-lg text-gray-500 leading-8">
                Within weeks, we are able to deliver automations which can save--and have saved--companies <span className='font-bold'>MILLIONS</span> of dollars per year.
            </p>
            <p className="mt-8 text-lg text-gray-500 leading-8">
                To date, we've saved large companies over <span className='font-bold'>$4M year-over-year</span> in operational costs.
            </p>
            <div>
            <h1 className='flex items-center mt-12'>
                <div className='flex-grow-1 m-4 h-2 mt-6 w-full  text-transparent bg-transparent border-2 border-tranparent border-b-gray-200 border-t-0 border-x-0'></div>
              <span className="mt-6 w-[200px] sm:w-[500px] text-2xl text-center font-bold tracking-tight text-gray-900">
                    How does it work?
              </span>
              <div className='flex-grow-1 m-4 h-2 mt-6 w-full text-transparent bg-transparent border-2 border-tranparent border-b-gray-200 border-t-0 border-x-0'></div>
            </h1>
            
              </div>
            <p className="mt-8 text-lg text-gray-500 leading-8">
                Think about it: If you (or an employee's) time is worth $25/hr--another way of looking at that is about 42 cents per minute.
            </p>
            <p className="mt-8 text-lg text-gray-500 leading-8">
                A 5 minute task?
            </p>
            <p className="mt-8 text-lg text-gray-500 leading-8">
                Try <span className='font-bold'>$2.08 !!!</span>
            </p>
            <p className="mt-8 text-lg text-gray-500 leading-8">
                And don't even get me started on a 20 minute task...  😱
            </p>
            <p className="mt-8 text-lg text-gray-500 leading-8">
                Or if your time is worth MORE than $25/hr...
            </p>

            <p className="mt-8 text-lg text-gray-500 leading-8">
                You can see how these things start to add up pretty quick. Even back to our "small" example of
                a 5-minute task by a low-level employee... If this is a daily task, it costs your company over
                <span className='font-bold'> $400 per year. </span>

            <p className="mt-8 text-lg text-gray-500 leading-8">
                A 20 minute task? <span className='font-bold'> $1,600 per year.</span>
            </p>
            <p className="mt-8 text-lg text-gray-500 leading-8">
                That's per employee, by the way. How many people do you have working for you?
            </p>
            <p className="mt-8 text-lg text-gray-500 leading-8">
                Even if you don't have employees; you have yourself. What is your time worth? You own your own business.
                You have a specialized skill that provides value, or some otherwise niche expertise... What is that worth? $100/hr? 
                <span className='font-bold'> Your own daily, 20 minute task is costs you $6,400 per year.</span>
            </p>
            <p className="mt-8 text-lg text-gray-500 leading-8">
                <span className='font-bold'>PER. YEAR. $6,400!!</span>
            </p>
            </p>
            
            <p className="mt-8 text-lg text-gray-500 leading-8">
              At LaunchPoint, these are the types of problems we love to solve.
              Schedule a meeting with us for a deep dive into your processes, and
              we will seek to find opportunities for automation and other
              solutions which will free you and our finances up to do what you do best.
            </p>

            <div className='w-full flex justify-center pt-6 mt-12'>
            <CTA text="Book a Consultation" consultation={true}/>
            </div>
            
          </div>
          <div className=" max-w-3xl p-6 mt-6">
            <h1 className='flex items-center justify-center'>
                
              <span className="mt-6 text-2xl text-center font-bold tracking-tight text-gray-900">
                    Wait, but... I want a mobile app...
              </span>
              
            </h1>
          <p className="mt-8 text-lg text-gray-500 leading-8">
              Don't worry! Even though we got our start doing industry-scale office automations, we have
              a deep network of engineers with a wide variety of specialities... And we've made sure our design software
              works not just for automation projects--but for large-scale mobile and web apps, too!
            </p>
            
            <p className="mt-8 text-lg text-gray-500 leading-8">
            In fact, that's actually become our favorite type of project. Helping small businesses rocket from 0 to scale
            is part of what we do best. 
            
            </p> 
            <p className="mt-8 text-lg text-gray-500 leading-8">
            It was sort of a happy accident, really. As it turns out: When you're a freak about efficiency, and seeking optimal,
            scalable solutions... <span className='italic'>That works on cell phones, too.</span> And web projects!
            </p> 
            
            <p className="mt-8 text-lg text-gray-500 leading-8">
              We provide full-service MVP solutions, and of course--App design and
            consultation is included. Simply sit down for a 90-minute design meeting with us, and within 72 hours you will be
            presented with a fully fleshed out project plan.
            </p>
            <p className="mt-8 text-lg text-gray-500 leading-8">
            You're welcome to take that to any team you like, but of course--building the app is what we're REALLY here for!!!
            </p>
            
            <p className="mt-8 text-lg text-gray-500 leading-8">
            Every project and product is different, and that's part of what we love about what we do. Let's sit down together
            and talk through your project. If we aren't a good fit--we stand behind a money-back guarantee. If we aren't able to
            solve your problem, or you're dissatisfied with our work in any way--we will refund your purchase, no questions asked.
            </p>
          </div>
          <div className='w-full flex justify-center pt-6'>
          <CTA text="Book a Consultation" consultation={true}/>
          </div>
        </div>
      </div>
    )
  }
  